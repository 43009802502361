import React from 'react';
import styled from 'styled-components';

import austin from '../assets/images/austin.png';
import calebgardner from '../assets/images/calebgardner.png';
import caleblecomte from '../assets/images/caleblecomte.png';
import imari from '../assets/images/imari.png';

import { colors } from '../components/Colors';
import { PageContainer } from '../components/StyledComponents';
import CallToAction from '../components/CallToAction';

const SectionContainer = styled.section`
  padding: 100px 20px;
  background-color: ${(props) => props.bgColor || colors.darkGray};
  color: ${colors.softWhite};
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    padding: 40px 15px;
  }
`;

const ContentWrapper = styled.div`
  max-width: 75%;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const Heading = styled.h2`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  font-style: italic;

`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  font-size: 18px;
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;

  &:before {
    content: '✔';
    color: #8cc63f;
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const TeamLayout = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Image = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;

  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const NameTitle = styled.div`
  margin-bottom: 10px;

  h3 {
    font-size: 32px;
    margin: 0;
  }

  p {
    font-size: 18px;
    margin: 5px 0 0 0;
    font-style: italic;
  }
`;

const Separator = styled.hr`
  margin: 20px 0;
  border: none;
  border-top: 1px solid ${colors.lightGray};
`;

const TeamMember = ({ name, title, imgSrc, description, bgColor }) => (
  <SectionContainer bgColor={bgColor}>
    <ContentWrapper>
      <TeamLayout>
        <Image src={imgSrc} alt={name} />
        <InfoContainer>
          <NameTitle>
            <h3>{name}</h3>
            <p>{title}</p>
          </NameTitle>
        </InfoContainer>
      </TeamLayout>
      <Separator />
      <Description>{description}</Description>
    </ContentWrapper>
  </SectionContainer>
);

const WhyUseTirrellStudio = () => (
  <SectionContainer bgColor={colors.darkGray}>
    <ContentWrapper>
      <Heading>Why Choose Us?</Heading>
      <Description>
        We might be a small online company, but we operate with the knowledge and capabilities of a much larger one. Our expertise spans a variety of fields, from web development to graphic design, and we’re constantly expanding our skill set. We’re always learning, evolving, and improving, allowing us to provide the best possible service.
      </Description>
    </ContentWrapper>
  </SectionContainer>
);

const Experience = () => (
  <SectionContainer bgColor={colors.darkestGray}>
    <ContentWrapper>
      <Heading>Past Experience of Tirrell Studio</Heading>
      <Description>
        At Tirrell Studio, we’ve had the pleasure of working on a variety of exciting projects, including:
      </Description>
      <List>
        <ListItem>Clean Choice Pressure Washing: Designed and developed a fully customized website to meet the needs of a growing pressure washing business.</ListItem>
        <ListItem>Realtor Custom Software: Built a solution that scrapes real estate listings and compiles them into a searchable spreadsheet for easy access.</ListItem>
        <ListItem>Chrome Extensions: Created custom extensions that automate form-filling using customer data, as well as other things like custom styles for different websites.</ListItem>
        <ListItem>Automation Tools: Developed software to automate company processes, including account creation, emails, and data updates.</ListItem>
        <ListItem>Web & Mobile Apps: Designed and maintained both front-end and back-end systems for various apps, ensuring seamless performance.</ListItem>
      </List>
    </ContentWrapper>
  </SectionContainer>
);

const Austin = () => (
  <TeamMember
    bgColor={colors.darkGray}
    name='Austin Tirrell'
    title='Fullstack Developer & Graphic Designer'
    imgSrc={austin}
    description={`"My journey into tech started at a young age, driven by curiosity and a love for creating things. As a kid, I was intrigued with modding video games, and by the age of 12, I had published my first Android app&mdash;something simple but deeply satisfying. Growing up, I also had a passion for design, inspired by my dad&rsquo;s early career in the field. Starting with Corel Draw and later moving to Adobe Illustrator, I began taking on design gigs on Fiverr, refining my skills and learning what clients need. Eventually, I developed a love for web development through The Odin Project&mdash;a free, community-supported course that I highly recommend. Eventually I landed my first job as a Junior Software Developer. My experience in that role, and eventually as a Software Developer. Along the way I've met some amazing people and decided to take on the challenge of building something from the ground up with those people. Most of us still have regular full-time jobs, but we're determined to build our own reputation and hope to provide you with the best possible service."`}
  />
);

const CalebGardner = () => (
  <TeamMember
    bgColor={colors.darkestGray}
    name='Caleb Gardner'
    title='Fullstack Developer'
    imgSrc={calebgardner}
    description={`"I've always loved anything to do with computers. My first foray into programming was in 5th grade on my Casio FX-9750GII graphing calculator. In high school, I learned coding properly, starting with Visual Basic, and have since taught myself several languages. My projects include a TTRPG character sheet app for Android and web, and a Go library to read squashfs archives."`}
  />
);

const CalebLecomte = () => (
  <TeamMember
    bgColor={colors.darkGray}
    name='Caleb Lecomte'
    title='Web Designer & Search Engine Optimizer'
    imgSrc={caleblecomte}
    description={`"Ever since I can remember, I’ve had a deep passion for computers, and that curiosity led me to a strong background in IT. I found my niche in web development, where I get to turn creative ideas into websites that not only look great but run smoothly. I'm always exploring new technologies and techniques to take my skills further, ensuring I bring fresh perspectives to every project."`}
  />
);

const Imari = () => (
  <TeamMember
    bgColor={colors.darkestGray}
    name='Imari Tirrell'
    title='Client Outreach Specialist'
    imgSrc={imari}
    description={`"At Tirrell Studio, my role is all about building relationships and making sure we're reaching the right audience. Whether it’s through managing social media accounts, sending emails, or connecting with potential clients, I’m passionate about helping our business grow."`}
  />
);

export default function About({ changePage }) {
  return (
    <PageContainer>
      <WhyUseTirrellStudio />
      <Experience />
      <Austin />
      <CalebGardner />
      <CalebLecomte />
      <Imari />
      <CallToAction changePage={changePage} />
    </PageContainer>
  );
}
