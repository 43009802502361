import React from "react";
import styled from "styled-components";

import { colors } from "../components/Colors";
import CallToAction from "../components/CallToAction";
import { PageContainer } from "../components/StyledComponents";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 20px;
  background: ${props => props.bgColor || colors.darkGray};
  color: ${props => props.textColor || colors.softWhite};
  text-align: ${props => props.align || 'left'};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;

  ul {
    width: 75%;
  }

  @media (max-width: 768px) {
    padding: 40px 15px;
  }
`;

const Heading = styled.h2`
  width: 75%;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Subheading = styled.h3`
  width: 75%;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const Description = styled.p`
  width: 75%;
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.6;
`;

const PriceDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.lightGreen};
  width: 75%;
  font-size: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 15px 10px;
  color: ${colors.darkGray};
`;

const PricingDetails = styled.div`
  width: 75%;
  background: ${colors.darkestGray};
  color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
`;

const PricingHeader = styled.h3`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const PricingItem = styled.p`
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 10px;

  &::before {
    content: "•";
    margin-right: 10px;
  }
`;

const StepList = styled.ul`
  width: 75%;
  list-style-type: none;
  padding: 0;
`;

const StepItem = styled.li`
  font-size: 20px;
  margin-bottom: 20px;
  position: relative;
  padding-left: 40px;
  
  &::before {
    content: "✔";
    position: absolute;
    left: 0;
    top: 0;
    color: #8cc63f;
    font-size: 24px;
  }
`;

const SummarySection = () => (
  <Section align="center">
    <Heading>Our Services</Heading>
    <Description>
      Tirrell Studio offers a comprehensive range of digital services tailored to your business needs. From website design and hosting, to custom software solutions, and branding packages, we've got you covered.
    </Description>
  </Section>
);

const WebsiteDesignHosting = () => (
  <Section bgColor={colors.darkestGray}>
    <Heading>Website Design & Hosting</Heading>
    <PriceDetails>
      <p>Starting at <strong>$2499</strong> design and setup fee, with a <strong>$95/month</strong> hosting and maintenance charge.</p>
    </PriceDetails>
    <Description>
      Our website design and hosting service includes a fully custom-built website that is tailored to your business, fast, mobile-responsive, and SEO-optimized.
    </Description>
    <Description>
      We build entirely from scratch, without relying on platforms like WordPress, so your monthly costs remain low. Contact us for more information.
    </Description>
    <Subheading>What You Get:</Subheading>
    <ul>
      <li>Custom Website Design</li>
      <li>Responsive for Mobile and Desktop</li>
      <li>SEO-Optimized</li>
      <li>Monthly Hosting & Maintenance</li>
    </ul>
  </Section>
);

const GraphicDesign = () => (
  <Section>
    <Heading>Graphic Design</Heading>
    <PriceDetails>
      <p>Prices vary for this service. Contact us for a free estimate!</p>
    </PriceDetails>
    <Description>
      We offer graphic design services including logo design, branding, business cards, and promotional materials that align with your business identity.
    </Description>
    <Subheading>Possibilities:</Subheading>
    <ul>
      <li>Logo Design</li>
      <li>Business Cards & Flyers</li>
      <li>Branding & Identity Design</li>
    </ul>
  </Section>
);

const CustomSoftware = () => (
  <Section bgColor={colors.darkestGray}>
    <Heading>Custom Software Solutions</Heading>
    <PriceDetails>
      <p>Prices vary for this service. Contact us for a free estimate!</p>
    </PriceDetails>
    <Description>
      We develop custom software applications, integrations, and automation tools to fit your business needs.
    </Description>
    <Subheading>What You Get:</Subheading>
    <ul>
      <li>Custom Application Development</li>
      <li>Custom Servers</li>
      <li>API Integrations</li>
      <li>Automation Services</li>
      <li>Mobile or Web Applications</li>
    </ul>
  </Section>
);

const BuildProcess = () => (
  <Section>
    <Heading>Our Build Process</Heading>
    <Description>
      We follow a streamlined process to deliver the best possible result. Here's what you can expect:
    </Description>
    <StepList>
      <StepItem>Free discovery call to understand your project.</StepItem>
      <StepItem>Plan and design the solution based on the scope.</StepItem>
      <StepItem>Follow-up call for review and feedback.</StepItem>
      <StepItem>Development begins after your approval.</StepItem>
    </StepList>
    <PricingDetails>
      <PricingHeader>Pricing:</PricingHeader>
      <PricingItem>10% deposit after the discovery call.</PricingItem>
      <PricingItem>40% payment upon design approval.</PricingItem>
      <PricingItem>50% upon project completion.</PricingItem>
      <PricingItem>Hosting starts at $75/month.</PricingItem>
    </PricingDetails>
  </Section>
);

export default function Services({ changePage }) {
  return (
    <PageContainer>
      <SummarySection />
      <WebsiteDesignHosting />
      <GraphicDesign />
      <CustomSoftware />
      <BuildProcess />
      <CallToAction changePage={changePage} />
    </PageContainer>
  );
}