import styled from 'styled-components';
import { colors } from './Colors';

export const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  width: 100%;
  background: ${colors.darkGray};
`;

export const ActionButton = styled.button`
    background: ${({ background }) => background || colors.darkestGray};
    color: ${colors.softWhite};
    padding: 15px 30px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;

    &:hover {
        background-color: ${colors.darkGray};
        color: ${colors.softWhite};
        border-color: ${colors.softWhite};
    }

    @media (max-width: 768px) {
        font-size: 16px;
        padding: 10px 20px;
    }
`;
