export const colors = {
  lightGreen: '#8cc63f',
  green: 'rgb(90, 116, 34)',
  white: '#fff',
  softWhite: '#e0e0e0',
  black: '#222529',
  gray: '#555555',
  darkGray: '#222529',
  darkestGray: '#1a1a1a',
  lightGray: '#999999'
};