import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

import { colors } from "../components/Colors";
import { ActionButton } from "../components/StyledComponents";
import CallToAction from "../components/CallToAction";
import { PageContainer } from "../components/StyledComponents";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 75px 20px;
  background: ${(props) => props.bgColor || colors.darkGray};
  color: ${(props) => props.textColor || colors.softWhite};
  text-align: center;
  width: 100%;
  
  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;

const Heading = styled.h2`
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
  color: ${colors.softWhite};

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const Text = styled.p`
  font-size: 20px;
  max-width: 800px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  max-width: 1200px;
  width: 100%;
  margin: 40px 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const Card = styled.div`
  background-color: ${colors.darkestGray};
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 40px;
  text-align: center;

  h3 {
    font-size: 28px;
    margin-bottom: 20px;
    color: ${colors.lightGreen};
  }

  p {
    font-size: 18px;
    color: ${colors.softWhite};
  }

  @media (max-width: 768px) {
    padding: 20px;

    h3 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Arrow = styled.button`
  background: none;
  border: none;
  font-size: 42px;
  cursor: pointer;
  color: #8cc63f;
  position: absolute;
  top: 50%;
  ${(props) => (props.direction === 'left' ? 'left: 20px;' : 'right: 40px;')}
  transform: translateY(-50%);
  
  &:hover {
    color: #222;
  }
`;

const swoopIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const TestimonialCard = styled.div`
  background: ${colors.darkestGray};
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  animation: ${(props) => (props.direction === 'in' ? swoopIn : swoopOut)} 0.5s ease-in-out forwards;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Services = ({ changePage }) => (
  <Section id="services">
    <Heading>Our Services</Heading>
    <Grid>
      <Card>
        <h3>Website Design, Development, and Hosting</h3>
        <p>Custom websites tailored to your brand, built for both desktop and mobile, ensuring high-quality user experiences.</p>
      </Card>
      <Card>
        <h3>Custom Software Solutions</h3>
        <p>Innovative software solutions built to solve your business problems, including automation, data processing, and more.</p>
      </Card>
      <Card>
        <h3>Graphic Design</h3>
        <p>Eye-catching logos, business cards, and marketing materials to help your brand stand out from the competition.</p>
      </Card>
    </Grid>
    <ButtonGroup>
      <ActionButton background={colors.green} onClick={() => changePage('services')}>Learn More</ActionButton>
    </ButtonGroup>
  </Section>
);

const WhyUs = ({ changePage }) => (
  <Section bgColor={colors.darkestGray}>
    <Heading>Why Choose Tirrell Studio?</Heading>
    <Text>
      At Tirrell Studio, we’re always learning and evolving to stay ahead in a rapidly changing industry. Our approach is highly collaborative, using an iterative process that ensures we work closely with you to build exactly what you need.
    </Text>
    <ActionButton background={colors.green} onClick={() => changePage('about')}>
      Read More
    </ActionButton>
  </Section>
);

const testimonials = [
  {
      name: 'Isai',
      title: 'Owner, Clean Choice Pressure Washing',
      message:
          '"Tirrell Studio is amazing with building your website. They did an amazing job on my business website, making sure I was pleased with it and answered my questions and was very available in making any changes I needed done to it. I would definitely recommend anyone to them!"',
  },
  {
      name: 'Niwakv',
      title: 'Owner, Nsooki Express Braiding',
      message:
          '"He did exactly what I wanted and beyond, he is patient, passionate, and professional. I really have nothing bad to say... He delivered!"',
  },
  {
      name: 'Chase',
      title: 'Owner, Good Under Pressure Soft Washing',
      message:
          '"Tirrell Studio designed my company logo and they did a great job. I especially liked how they would often check in with me to make sure they were designing something that matched my brand and expectations. I would highly recommend working with them."',
  }
];

const swoopOut = keyframes`
  from {
      transform: translateX(0);
      opacity: 1;
  }
  to {
      transform: translateX(-100%);
      opacity: 0;
  }
`;

const Header = styled.h2`
  position: absolute;
  top: 75px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;

  @media (max-width: 768px) {
      top: 20px;
  }
`;

const TestimonialSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${colors.darkGray};
  color: ${colors.softWhite};
  text-align: center;
  position: relative;
  height: 600px;
  overflow-x: hidden;
  overflow-y: hidden;

  @media (max-width: 768px) {
      padding: 100px 20px;
  }
`;

const Message = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
`;

const Author = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Title = styled.h4`
  font-size: 16px;
  color: ${colors.softWhite};
`;

function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState('in');
  const [swipeTime, setSwipeTime] = useState(0);

  const handleNext = () => {
      setDirection('out');
      setTimeout(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
          setDirection('in');
      }, 500);
      setSwipeTime(0);
  };

  useEffect(() => {
      const interval = setInterval(() => {
          setSwipeTime((prevTime) => prevTime + 1);
      }, 1000);

      return () => clearInterval(interval);
  }, []);

  useEffect(() => {
      if (swipeTime >= 10) {
          handleNext();
      }
  }, [swipeTime]);

  return (
      <TestimonialSection>
          <Header>Testimonials</Header>
          <TestimonialCard key={currentIndex} direction={direction}>
              <Message>{testimonials[currentIndex].message}</Message>
              <Author>{testimonials[currentIndex].name}</Author>
              <Title>{testimonials[currentIndex].title}</Title>
          </TestimonialCard>
          <Arrow direction='right' onClick={handleNext}>
              {'>'}
          </Arrow>
      </TestimonialSection>
  );
}

export default function Home({ changePage }) {
  return (
    <PageContainer>
      <Services changePage={changePage} />
      <WhyUs changePage={changePage} />
      <Testimonials />
      <CallToAction changePage={changePage} />
    </PageContainer>
  );
}