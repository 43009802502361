import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import logo from '../assets/images/logo-white.svg';

import { colors } from './Colors';

const StyledFooter = styled.footer`
    background: ${colors.darkestGray} !important;
    width: 100%;
`;

const StyledLogo = styled.img`
    width: 300px;
`;

export default function Services() {
    return (
        <StyledFooter className="bg-dark text-light">
            <Container className="py-5">
                <Row>
                    <Col md={6} sm={12}>
                        <StyledLogo src={logo} className="img-fluid" alt="Tirrell Studio" />
                        <p>Your partner in the cloud.</p>
                    </Col>
                    <Col md={3} sm={6}>
                        <h5>Quick Links</h5>
                        <ul className="list-unstyled">
                            <li><a href="/" className="text-light">Home</a></li>
                            <li><a href="/services" className="text-light">Services</a></li>
                            <li><a href="/contact" className="text-light">Contact</a></li>
                        </ul>
                    </Col>
                    <Col md={3} sm={6}>
                        <h5>Contact Us</h5>
                        <p>Email: <a href="mailto:tirrellstudio@gmail.com" className="text-light">tirrellstudio@gmail.com</a></p>
                        <p>Phone: <a href="tel:+12198097690" className="text-light">(219) 809-7690</a></p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center mt-4">
                        <p>&copy; {new Date().getFullYear()} Tirrell Studio. All rights reserved.</p>
                    </Col>
                </Row>
            </Container>
        </StyledFooter>
    );
}