import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import { PageContainer}  from '../components/StyledComponents';
import { colors } from '../components/Colors';
import Altcha from '../components/Altcha';

const ContactFormSection = styled.section`
  background: ${colors.darkGray};
  padding: 100px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${colors.softWhite};

  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;

const FormContainer = styled.div`
  background: ${colors.darkestGray};
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  color: ${colors.softWhite};
`;

const Heading = styled.h2`
  text-align: center;
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 10px;
  font-size: 18px;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 16px;
  background: ${colors.darkGray};
  color: ${colors.softWhite};
`;

const Textarea = styled.textarea`
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 16px;
  background: ${colors.darkGray};
  color: ${colors.softWhite};
`;

const Select = styled.select`
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 16px;
  background: ${colors.darkGray};
  color: ${colors.softWhite};
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
`;

const SubmitButton = styled.button`
  padding: 15px;
  background-color: ${colors.green};
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  text-transform: uppercase;
  height: 75px;
  width: 100%;

  &:hover {
    background-color: #222529;
  }
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #8cc63f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SuccessMessage = styled.p`
  color: green;
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
`;

const Checkbox = styled.input`
  width: 50px;
  height: 50px;
  margin: 0 15px;
  accent-color: ${colors.green};
`;

const CheckboxLabel = styled.label`
  width: 50%;
  line-height: 1;
  font-size: 16px;
`;

export default function Contact() {
  const altchaRef = useRef(null)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: '',
    message: '',
    preferredCallTime: '',
    urgency: '',
    newsletter: true,
    altchaToken: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.altchaToken = altchaRef.current?.value;
    setIsLoading(true);
    setError(false);

    try {
      const response = await fetch('https://api.tirrell.studio/tirrell-studio/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccess(true);
        setFormData({
          name: '',
          email: '',
          phone: '',
          service: '',
          message: '',
          preferredCallTime: '',
          urgency: '',
          newsletter: true,
          altchaToken: '',
        });
      } else {
        const j = await response.json()
        if(j.error){
          setError(`There was an issue sending your message: ${j.error}. Please try again.`);
        }else{
          setError("There was an issue sending your message. Please try again.")
        }
      }
    } catch (err) {
      setError('There was an error submitting the form. Please try again later.');
    }

    setIsLoading(false);
  };

  return (
    <PageContainer>
      <ContactFormSection>
        <FormContainer>
          <Heading>Contact Us</Heading>
          <Form onSubmit={handleSubmit}>
            <Label>Name</Label>
            <Input type="text" name="name" value={formData.name} onChange={handleChange} required />

            <Label>Email</Label>
            <Input type="email" name="email" value={formData.email} onChange={handleChange} required />

            <Label>Phone</Label>
            <Input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />

            <Label>What service are you looking for?</Label>
            <Select name="service" value={formData.service} onChange={handleChange} required>
              <option value="">Select a service</option>
              <option value="Website Design and Hosting">Website Design and Hosting</option>
              <option value="Custom Software Solution">Custom Software Solution</option>
              <option value="Graphic Design">Graphic Design</option>
              <option value="Consultation">Consultation</option>
            </Select>

            <Label>What days and hours are best for a free discovery call?</Label>
            <Input type="text" name="preferredCallTime" value={formData.preferredCallTime} onChange={handleChange} required />

            <Label>How urgent is this project?</Label>
            <Select name="urgency" value={formData.urgency} onChange={handleChange} required>
              <option value="">Select urgency</option>
              <option value="1 Month or less">1 Month or less</option>
              <option value="1-3 Months">1-3 Months</option>
              <option value="Flexible">Flexible</option>
            </Select>

            <Label>Additional Details</Label>
            <Textarea name="message" rows="6" value={formData.message} onChange={handleChange} placeholder="Tell us more about your project..." required />

            <CheckboxContainer>
              <Altcha ref={altchaRef} />
              <Checkbox type="checkbox" name="newsletter" checked={formData.newsletter} onChange={handleChange} />
              <CheckboxLabel>Subscribe to our newsletter</CheckboxLabel>
            </CheckboxContainer>

            <SubmitButton type="submit">Send</SubmitButton>

            {isLoading && <Spinner />}
            {success && <SuccessMessage>Your message was sent successfully. We'll be in touch soon!</SuccessMessage>}
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </Form>
        </FormContainer>
      </ContactFormSection>
    </PageContainer>
  );
}
