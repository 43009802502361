import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';

import Home from './pages/Home';
import Contact from './pages/Contact';
import Services from './pages/Services';
import About from './pages/About';
import Unsubscribe from './pages/Unsubscribe';
import NotFound from './pages/NotFound';

import { PageContainer } from './components/StyledComponents';

const pageTransition = {
  hidden: { opacity: 0, x: '-100%' },
  visible: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: '100%' },
};

const pages = ['home', 'contact', 'services', 'about'];

export default function App() {
  return (
    <Router>
      <PageWrapper />
    </Router>
  );
}

function PageWrapper() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(pages.includes(location.pathname.substring(1)) ? location.pathname.substring(1) : 'home');

  useEffect(() => {
    const currentPath = location.pathname.substring(1);
    if (pages.includes(currentPath)) {
      setActivePage(currentPath);
    } else {
      setActivePage('home');
    }
  }, [location]);

  const changePage = (page) => {
    setActivePage(page);
    if (page === 'home') navigate('/');
    else navigate(`/${page}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <PageContainer>
      {(pages.includes(location.pathname.substring(1)) || location.pathname === '/') && (
        <>
          <Header changePage={changePage} activePage={activePage} />
          <Hero activePage={activePage} changePage={changePage} />
        </>
      )}

      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <motion.div
                key="home"
                variants={pageTransition}
                initial="hidden"
                animate="visible"
                exit="exit"
                transition={{ duration: 0.25 }}
              >
                <Home changePage={changePage} />
              </motion.div>
            }
          />
          <Route
            path="/contact"
            element={
              <motion.div
                key="contact"
                variants={pageTransition}
                initial="hidden"
                animate="visible"
                exit="exit"
                transition={{ duration: 0.25 }}
              >
                <Contact />
              </motion.div>
            }
          />
          <Route
            path="/services"
            element={
              <motion.div
                key="services"
                variants={pageTransition}
                initial="hidden"
                animate="visible"
                exit="exit"
                transition={{ duration: 0.25 }}
              >
                <Services />
              </motion.div>
            }
          />
          <Route
            path="/about"
            element={
              <motion.div
                key="about"
                variants={pageTransition}
                initial="hidden"
                animate="visible"
                exit="exit"
                transition={{ duration: 0.25 }}
              >
                <About />
              </motion.div>
            }
          />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AnimatePresence>

      <Footer />
    </PageContainer>
  );
}