import React from "react";
import styled from "styled-components";

import { ActionButton } from "./StyledComponents";
import { colors } from "./Colors";

const CallToActionSection = styled.section`
    background: ${colors.green};
    color: ${colors.softWhite};
    padding: 80px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (max-width: 768px) {
        padding: 50px 20px;
    }
`;

const Heading = styled.h2`
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: bold;
    color: ${colors.softWhite};

    @media (max-width: 768px) {
        font-size: 28px;
    }
`;

const Subheading = styled.p`
    font-size: 20px;
    margin-bottom: 40px;
    max-width: 600px;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }
`;

export default function CallToAction({
  changePage
}) {
    return (
        <CallToActionSection>
            <Heading>Ready to Bring Your Vision to Life?</Heading>
            <Subheading>Contact us today so we can discuss how we can help you create the perfect website, software solution, or graphic design for your business.</Subheading>
            <ButtonGroup>
                <ActionButton onClick={() => changePage('contact')}>Get Started</ActionButton>
            </ButtonGroup>
        </CallToActionSection>
    );
}
