import React, { useState } from 'react';
import Styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';

import { colors } from '../components/Colors';

const PageContainer = Styled.div`
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${colors.softWhite};
`;

const FormContainer = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.darkestGray};
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;

  label {
    text-align: left;
    width: 100%;
  }
`;

const StyledInput = Styled.input`
  width: 100%;
  padding: 10px;
`;

export default function Unsubscribe() {
  const [searchParams] = useSearchParams();
  const emailFromParams = searchParams.get('email');
  const [userEmail, setUserEmail] = useState(emailFromParams || '');
  const [status, setStatus] = useState('');

  const handleEmailChange = (e) => {
    setUserEmail(e.target.value);
  };

  const handleUnsubscribe = async () => {
    setStatus('Processing...');

    try {
      const response = await fetch(`https://api.tirrell.studio/tirrell-studio/unsubscribe`, {
        method: 'POST',
        body: JSON.stringify({ email: userEmail })
      });

      if (response.ok) {
        setStatus('You have been unsubscribed successfully.');
      } else {
        setStatus('Unknown error occurred while trying to unsubscribe.');
      }
    } catch (error) {
      console.error('Error unsubscribing:', error);
      setStatus('Unknown error occurred while trying to unsubscribe.');
    }
  };

  return (
    <PageContainer>
      <h1>Unsubscribe</h1>
      <p>If you no longer wish to receive our emails, you can unsubscribe below.</p>
      
      <FormContainer>
        <label htmlFor="email">Email:</label>
        <StyledInput
          type="email"
          id="email"
          className="form-control"
          value={userEmail}
          onChange={handleEmailChange}
          required
        />
        <button
          className="btn btn-danger mt-3"
          onClick={handleUnsubscribe}
          disabled={!userEmail}
        >
          Unsubscribe
        </button>
      </FormContainer>

      {status && <p className="mt-3">{status}</p>}
    </PageContainer>
  );
}