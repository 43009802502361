import React from 'react';
import Styled from 'styled-components';

import { colors } from '../components/Colors';

const PageContainer = Styled.div`
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${colors.softWhite};

  a {
    color: ${colors.lightGreen};
    background: ${colors.darkestGray};
    padding: 15px 30px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    border: 1px solid ${colors.lightGreen};
    margin: 20px 0;

    &:hover {
        background-color: ${colors.darkGray};
        color: ${colors.softWhite};
        border: 1px solid ${colors.softWhite};
    }
  }
`;

export default function NotFound() {
  return (
    <PageContainer>
      <h1>Uh oh!</h1>
      <p>The page you're looking for doesn't exist.</p>

      <a href="/">Go back</a>
    </PageContainer>
  );
}