import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import { ActionButton } from './StyledComponents';
import { colors } from './Colors';

const OverlayTextContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    color: ${colors.softWhite};
    text-align: center;

    h1 {
        font-size: 48px;
        text-align: center;
    }

    h3 {
        font-size: 32px;
        text-align: center;
        margin-bottom: 40px;
    }

    @media (max-width: 768px) {
        width: 100%;

        h1 {
            font-size: 32px;
            width: 100%;
        }

        h3 {
            font-size: 24px;
            margin-bottom: 20px;
            width: 100%;
        }
`;

const VideoBackgroundContainer = styled.div`
    width: 100%;
    height: 90vh;
    overflow: hidden;
    position: relative;
    border-radius: 0 0 50px 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    z-index: 100;

    @media (max-width: 768px) {
        height: 80vh;
        border-radius: 0;
    }
`;

const VideoBackground = styled.video`
    width: 105%;
    height: 105%;
    object-fit: cover;
    position: absolute;
    top: -2.5%;
    left: -2.5%;
    filter: blur(10px);
    pointer-events: none;
`;

const HeroOverlay = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 100px 40px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
`;

const ButtonGroup = styled(motion.div)`
    display: flex;
    gap: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }
`;

const StyledArrowIcon = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  color: ${colors.softWhite};
  font-size: 48px;
  margin-top: 50px;
  opacity: 0.25;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const pageTransition = {
  hidden: { opacity: 0, x: "-100%" },
  visible: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: "100%" },
};

const contentMap = {
  home: {
    heading: 'Welcome to Tirrell Studio',
    description: 'Professional Websites and Software Solutions',
    buttonText: 'Get Started',
    buttonAction: 'contact',
  },
  about: {
    heading: 'About Us',
    description: 'Learn more about Tirrell Studio and what we offer.',
    buttonText: 'Get In Touch',
    buttonAction: 'contact',
  },
  contact: {
    heading: 'Contact Us',
    description: 'Send us a message or give us a call to get started!'
  },
  services: {
    heading: 'Our Services',
    description: 'Explore the variety of services we offer.',
    buttonText: 'Contact Us',
    buttonAction: 'contact',
  },
};

export default function Hero({ activePage, changePage }) {
  const [currentContent, setCurrentContent] = useState(contentMap[activePage]);

  useEffect(() => {
    setCurrentContent(contentMap[activePage]);
  }, [activePage]);

  const scrollToSection = () => {
    const firstSection = document.querySelector("section h2");
    if (firstSection) {
      firstSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <VideoBackgroundContainer>
      <VideoBackground autoPlay loop muted playsInline>
        <source src='https://s3.amazonaws.com/tirrell.studio-assets/hero.mp4' type='video/mp4' />
        Your browser does not support the video tag.
      </VideoBackground>
      <HeroOverlay>
        <AnimatePresence mode='wait'>
          <OverlayTextContainer
            key={activePage}
            variants={pageTransition}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.25 }}
          >
            <h1>{currentContent.heading}</h1>
            <h3>{currentContent.description}</h3>
            {activePage !== 'contact' && (
              <ButtonGroup
                variants={pageTransition}
                initial="hidden"
                animate="visible"
                exit="exit"
                transition={{ duration: 0.25}}
              >
                <ActionButton onClick={() => changePage(currentContent.buttonAction)}>
                  {currentContent.buttonText}
                </ActionButton>
              </ButtonGroup>
            )}
          </OverlayTextContainer>
        </AnimatePresence>
        <StyledArrowIcon icon={faArrowDown} onClick={scrollToSection} />
      </HeroOverlay>
    </VideoBackgroundContainer>
  );
}